import { useEffect, useState } from 'react';
import { Switch } from 'antd';

function GrowerFilterSwitch({ getSAGrowersByYear, selectedYear, messages }) {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    getSAGrowersByYear(selectedYear, toggle);
  }, [toggle]);

  const onChange = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <label style={{ marginRight: '5px' }}>{messages.all_growers}</label>
      <Switch onChange={onChange} />
      <label style={{ marginLeft: '5px' }}>{messages.active_growers}</label>
    </div>
  );
}

export default GrowerFilterSwitch;
