import {
  GA_SERVICE,
  OKTA_DOMAIN,
  REDIRECT_DOMAIN,
  getEnvironmentValue,
} from '../../envConfig';

export const BASE_URL = getEnvironmentValue(GA_SERVICE) || '';

// Base URL for Okta services
const OKTA_BASE_URL = getEnvironmentValue(OKTA_DOMAIN) || '';
const REDIRECT_BASE_URL = getEnvironmentValue(REDIRECT_DOMAIN) || '';

//user profile
export const GET_USER_PROFILE = (
  email,
  userType,
  seedAdvisorUUID,
  zdPartnershipUuid
) =>
  `${BASE_URL}/login/get-user-profile?email=${email}${
    userType ? '&type=' + userType : ''
  }${seedAdvisorUUID ? '&sa=' + seedAdvisorUUID : ''}${
    zdPartnershipUuid ? `&zd=${zdPartnershipUuid}` : ''
  }`;
//verify user
export const VERIFY_USER = `${BASE_URL}/verify-user`;
export const EMAIL_FORGOT_PASSWORD = `${BASE_URL}/email-forgot-password`;
export const RESET_PASSWORD = `${BASE_URL}/reset-password`;
export const VALIDATE_PASSWORD_TOKEN = `${BASE_URL}/validate-password-token`;

// Reset Password using OKTA Services
export const SMS_FORGOT_PASSWORD = `${OKTA_BASE_URL}/api/v1/authn/recovery/password`;
export const SMS_FORGOT_PASSWORD_RELAY_STATE = `${REDIRECT_BASE_URL}/forgotpassword`;
export const VERIFY_OTP = `${OKTA_BASE_URL}/api/v1/authn/recovery/factors/sms/verify`;
export const SMS_RESET_PASSWORD = `${OKTA_BASE_URL}/api/v1/authn/credentials/reset_password`;

export const ADMIN_CHANGE_GROWER_PASSWORD = `${BASE_URL}/admin-change-grower-password`;

//Growers
const GROWERS = `${BASE_URL}/growers`;
export const INDV_GROWER = (id) => `${GROWERS}/${id}`;
export const ADD_NEW_GROWER = `${GROWERS}/signup`;
export const UPSERT_SEED_ADVISOR = (id) =>
  `${GROWERS}/${id}/upsert-seed-advisor`;
export const APPLY_FOR_CREDIT = `${GROWERS}/apply-for-credit`;
export const APPLY_FOR_NEW_CREDIT = `${GROWERS}/apply-for-new-credit`;
export const GET_GROWER_BY_PRIMARY_SA_ID = (sa_id) =>
  `${BASE_URL}/growers_by_sa/${sa_id}`;
export const MAKE_PAYMENT = `${GROWERS}/make-payment`;
export const UPDATE_JPM_TRANSACTION = `${GROWERS}/update-jpm-transaction`;
export const GROWER_NOTIFICATIONS = (id) => `${GROWERS}/${id}/notifications`;
export const UPDATE_GROWER_NOTIFICATIONS = (notificationId) =>
  `${GROWERS}/${notificationId}/update-notification`;
export const INDV_GROWER_PROFILE = (pid, gid) =>
  `${BASE_URL}/parent/${pid}/grower/${gid}`;
export const GET_BULLETINS = (userType) => `${BASE_URL}/bulletins/${userType}`;
export const GET_STATEMENT_BALANCES = (grower_uuid) =>
  `${BASE_URL}/statement-balances?grower_uuid=${grower_uuid}`;
export const GET_STATEMENT_PDF = (statement_balance_uuid) =>
  `${BASE_URL}/statement-balances/${statement_balance_uuid}/pdf`;
export const GROWER_STATEMENTS_EXPORT_ALL = (uuid) =>
  `${BASE_URL}/statement-balances/${uuid}/statements-export`;
export const EMAIL_SEED_ADVISOR = `${BASE_URL}/growers/email-seed-advisor`;
export const GROWER_APPLICATION = `${BASE_URL}/grower-application`;
export const ADMIN_RESET_GROWER_PASSWORD = `${BASE_URL}/admin-reset-grower-password`;
export const UPDATE_GROWER_FARM_ADDRESS = `${GROWERS}/updateGrowerFarmAddress`;

//Seed advisors
const SEED_ADVISOR = `${BASE_URL}/seed-advisers`;
export const PUT_INDV_SEED_ADVISORS = (id) => `${SEED_ADVISOR}/${id}`;
export const GET_SA_PROFILE = (id) => `${BASE_URL}/seed_adv/${id}`;
export const UPDATE_ACK = (id) => `${SEED_ADVISOR}/acknowledgement/${id}`;
export const GET_GROWERS = (args) => {
  const { uuid, year, includeActiveGrowersOnly } = args;
  return `${SEED_ADVISOR}/get-growers/${uuid}/${year}?includeActiveGrowersOnly=${includeActiveGrowersOnly}`;
};
export const SEARCH_SEED_ADVISOR = (id) => `${SEED_ADVISOR}/search-sa/${id}`;
export const SA_GET_COLLECTION_DATA = (uuid, salesYear) =>
  `${SEED_ADVISOR}/sa-collection-data/${uuid}/${salesYear}`;
export const SA_SETUP_ACCOUNT = `${SEED_ADVISOR}/setup-account`;
export const SA_Login_ACCOUNT = `${SEED_ADVISOR}/login`;
export const GET_SA_FOR_GROWER = `${BASE_URL}/getSeedAdvisorsByGrower`;

// Grower-Seed-Advisor
export const GROWER_SEED_ADVISOR = `${BASE_URL}/grower-seed-advisors`;
export const UPDATE_MAPPING = (id) => `${BASE_URL}/grower-seed-advisors/${id}`;

// ZD Partnerships
const ZD_PARTNERSHIPS = `${BASE_URL}/ZD-partnership`;
export const GET_ZD_PARTNERSHIP_BY_SA_ID = (sa_id) =>
  `${BASE_URL}/partnership_by_sa/${sa_id}`;
export const INDV_ZD_PARTNERSHIP = (id) => `${ZD_PARTNERSHIPS}/${id}`;
export const UPDATE_ZD_GROWER_EMAIL = `${ZD_PARTNERSHIPS}/update-grower-email/`;
export const RESET_INVITE = (uuid) => `${ZD_PARTNERSHIPS}/reset-invite/${uuid}`;

//Email
export const EMAIL_GROWER = `${SEED_ADVISOR}/email-grower`;
export const INVITE_EXISTING_GROWER = `${SEED_ADVISOR}/invite-existing-grower`;

//Bulletins
export const BULLETIN = `${BASE_URL}/bulletin`;
export const BULLETINS = `${BASE_URL}/bulletins`;
export const BULLETINS_AFTER_DATE = (date) =>
  `${BULLETIN}?start_date=start_date_gte=${date}`;
export const INDV_BULLETIN = (uuid) => `${BULLETIN}/${uuid}`;

//Admin
export const EXPORT_DATA = `${BASE_URL}/exportdata`;

const BASE_RESOURCE_URL = `${BASE_URL}/resource`;
export const GET_RESOURCE = (uuid) => `${BASE_RESOURCE_URL}/${uuid}`;
export const SAVE_RESOURCE = BASE_RESOURCE_URL;
export const UPDATE_RESOURCES = `${BASE_RESOURCE_URL}/update`;
export const DELETE_RESOURCE = (uuid) => `${BASE_RESOURCE_URL}/${uuid}`;
export const DOWNLOAD_RESOURCE = (uuid) =>
  `${BASE_RESOURCE_URL}/download/${uuid}`;
export const GET_RESOURCES = (userType) =>
  `${BASE_URL}/resources?userType=${userType.toLowerCase()}`;
export const GET_MOST_RECENT_TC_RESOURCES = () =>
  `${BASE_URL}/resource/getMostRecentGrowerTC`;
export const GET_BATCH_JOB_HISTORY = `${BASE_URL}/batch-job-queue-recent-history`;
export const GET_BATCH_JOB_CONFIGURATIONS = `${BASE_URL}/batch-job-queue-configurations`;
export const SAVE_BATCH_JOB = `${BASE_URL}/batch-job-queue`;
export const DELETE_BATCH_JOB = (uuid) => `${BASE_URL}/batch-job-queue/${uuid}`;

export const GET_STATEMENT_MARKETING_ADS = `${BASE_URL}/statement-marketing-ads`;
export const GET_GROWER_SAP_PAYMENT_COMPARISON = (requestData) =>
  `${BASE_URL}/growers/sap-payment-comparison?grower_ids=${requestData.grower_ids}&crop_year=${requestData.crop_year}`;
export const DELETE_STATEMENT_MARKETING_AD = (uuid) =>
  `${BASE_URL}/statement-marketing-ads/${uuid}`;
export const VIEW_STATEMENT_MARKETING_AD = (uuid) =>
  `${BASE_URL}/statement-marketing-ads/view/${uuid}`;
export const SAVE_STATEMENT_MARKETING_AD = `${BASE_URL}/statement-marketing-ads`;

export const GET_ALL_GROWERS_ADMIN = (year) =>
  `${BASE_URL}/growers-with-payment-due/${year}`;
export const EMAIL_GROWERS_ADMIN = `${BASE_URL}/email-growers-payment-due`;

export const SEARCH_ADMIN_STATEMENTS = `${BASE_URL}/statement-balances/admin-search`;
export const GROWER_STATEMENTS_ADMIN_EXPORT = `${BASE_URL}/statement-balances/admin-export`;

//Settings
export const SAVE_APPLY_DUE_DATE = `${BASE_URL}/settings/apply-due-date`;
export const GET_SETTINGS = `${BASE_URL}/settings`;
export const UPDATE_SEED_ADVISOR_STATUS = `${BASE_URL}/settings/update-seed-advisor`;

//sales-representatives
export const GET_GROWERS_SA_BY_SALES_REP_ID = (args) =>
  `${BASE_URL}/growers_sa_by_sr/${args.salesGroup}/${args.year}`;
export const GET_GROWERS_SA_BY_SALES_REP = (srId, salesYear) =>
  `${BASE_URL}/growers_sa/${srId}/${salesYear}`;
export const GET_SEED_ADVISER_BY_SA = (srId, saId) =>
  `${BASE_URL}/sales_representative/${srId}/seed_adviser/${saId}`;
export const INDV_SA_GROWER_PROFILE_BY_SR = (srId, gid) =>
  `${BASE_URL}/sales_rep/${srId}/grower/${gid}`;
export const LOAD_GROWER_STATEMENT_BY_SR = (sr_id, id) =>
  `${BASE_URL}/sales_rep/${sr_id}/growers/${id}/statements`;
export const GET_ZD_PARTNERSHIP_BY_SA_ID_BY_SR = (sr_id, sa_id) =>
  `${BASE_URL}/sales_rep/${sr_id}/partnership_by_sa/${sa_id}`;

//Customer Support
export const CAS_GET_DASHBOARD = (args) =>
  `${BASE_URL}/cas-dashboard/${args.district}/${args.year}`;
export const CAS_ALL_GROWER = (args) =>
  `${BASE_URL}/cas-growers/${args.district}/${args.year}`;
export const CAS_ALL_SA = (district, salesYear) =>
  `${BASE_URL}/cas-seed-advisors/${district}/${salesYear}`;
export const CAS_GET_DISTRICTS = `${BASE_URL}/cas-districts`;
export const CAS_GET_GROWER_STATES = `${BASE_URL}/cas-grower-states`;
export const CAS_SEARCH = `${BASE_URL}/cas-search`;
export const CAS_GET_COLLECTION_DATA = (district, salesYear) =>
  `${BASE_URL}/cas-collection-data/${district}/${salesYear}`;

// Okta Error
export const SET_OKTA_ERROR = `${BASE_URL}/set-okta-error`;

//MFA(Multi factor Authentication)
export const SMS_FORGOT_PASSWORD_IS_PHONE_NUMBER_EXISTS = `${BASE_URL}/validate-phone-and-send-otp`;
export const MFA_VERIFY_PHONE = `${BASE_URL}/verify-phone`;
export const MFA_VERIFY_OTP = `${BASE_URL}/verify-otp`;
